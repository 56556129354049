<template>
  <div>
    <el-data-table ref="table" v-bind="tableConfig" :columns="columns">
    </el-data-table>

    <el-dialog :title="title" :visible.sync="dialogFormVisible" :close-on-press-escape="false" :close-on-click-modal="false"  :before-close="closeDialog">
      <el-form :model="form" :rules="rules" ref="ruleUser">
                    <el-form-item label='序号' prop='id' :label-width="formLabelWidth" v-if="false">
              <el-input placeholder='请输入序号' clearable autocomplete="off" v-model='form.id' size="small"  />
            </el-form-item>
            <el-form-item label='商品类型' prop='typeid' :label-width="formLabelWidth">
                <el-select v-model="form.typeid" placeholder="请选择">
                    <el-option
                            v-for="item in typeList"
                            :key="item.id"
                            :label="item.typename"
                            :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label='商品名称' prop='goodsname' :label-width="formLabelWidth">
              <el-input placeholder='请输入商品名称' clearable autocomplete="off" v-model='form.goodsname' size="small"  />
            </el-form-item>
            <el-form-item label='商品价格/月' prop='price' :label-width="formLabelWidth">
              <el-input placeholder='请输入商品价格' clearable autocomplete="off" v-model='form.price' size="small"  />
            </el-form-item>
            <el-form-item label='商品详情' prop='detail' :label-width="formLabelWidth">
                <editor :init="editorInit" v-model="form.detail" />
            </el-form-item>
          <el-form-item label='首页图片' prop='homeimg' :label-width="formLabelWidth">
              <el-upload
                      class="avatar-uploader"
                      action="/api/file/upload"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccessHome"
                      :before-upload="beforeAvatarUpload">
                  <img v-if="form.homeimg" :src="form.homeimg" class="avatar1">
                  <i v-else class="el-icon-plus avatar-uploader-icon1"></i>
              </el-upload>
          </el-form-item>
            <el-form-item label='商品图片' prop='img' :label-width="formLabelWidth">
                <el-upload
                        class="avatar-uploader"
                        action="/api/file/upload"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload">
                    <img v-if="form.img" :src="form.img" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </el-form-item>
            <el-form-item label='售卖国家' prop='country' :label-width="formLabelWidth">
                <el-select v-model="form.country" multiple filterable collapse-tags v-bind="$attrs" v-on="$listeners">
                    <el-checkbox v-model="check" style="margin-left: 20px;"  :indeterminate="form.country.length !== countryList.length" @change="selectAll">
                        全选
                    </el-checkbox>
                    <el-option
                            v-for="item in countryList"
                            :key="item.twocode"
                            :label="item.shortname"
                            :value="item.twocode"
                    />
                </el-select>
            </el-form-item>
<!--            <el-form-item label='销售数量' prop='num' :label-width="formLabelWidth">-->
<!--                <el-input-number placeholder='请输入销售数量' clearable autocomplete="off" v-model='form.num' :precision="0" :step="1" :min="0"></el-input-number>-->
<!--            </el-form-item>-->
          <el-form-item label='车辆长度' prop='maxnum' :label-width="formLabelWidth">
              <el-input-number placeholder='请输入车辆长度' clearable autocomplete="off" v-model='form.maxnum' :precision="0" :step="1" :min="0"></el-input-number>
          </el-form-item>
          <el-form-item label='售卖类型' prop='type' :label-width="formLabelWidth">
              <el-select v-model="form.type" placeholder="请选择">
                  <el-option
                          v-for="item in typeOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                  </el-option>
              </el-select>
          </el-form-item>
          <el-form-item label='是否可买' prop='isbuy' :label-width="formLabelWidth">
              <el-select v-model="form.isbuy" placeholder="请选择">
                  <el-option
                          v-for="item in isbuyOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value">
                  </el-option>
              </el-select>
          </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">取 消</el-button>
        <el-button type="primary" @click="submitUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { insertGoodsSpu,updateGoodsSpu,deleteGoodsSpu,bacthDelGoodsSpu,uploadFile,getGoodstypeList,getCountryinfoAll } from "@/request/requests";
  import { MessageSuccess,MessageError,MessageInfo } from "@/util/util"
  import Editor from '@tinymce/tinymce-vue'
  export default {
      components: {
          Editor
      },
    name: "user",
    data(){
      const typeOptions=[
          {
              value:"1",
              label:"共享"
          },
          {
              value:"2",
              label:"独享"
          },
      ]
      const isbuyOptions=[
          {
              value:"1",
              label:"是"
          },
          {
              value:"0",
              label:"否"
          },
      ]
      const columns = [
          {type: 'selection'},
          {prop: 'id',label: '序号'},
          {prop: 'typeid',label: '商品类型id'},
          {prop: 'goodsname',label: '商品名称'},
          {prop: 'price',label: '商品价格'},
          // {prop: 'detail',label: '商品详情'},
          {prop: 'homeimg',label: '首页图片',formatter: row => this.homeimgState(row)},
          {prop: 'img',label: '商品图片',formatter: row => this.imgState(row)},
          {prop: 'country',label: '售卖国家',showOverflowTooltip:true},
          // {prop: 'num',label: '销售数量'},
          {prop: 'maxnum',label: '车辆长度'},
          {prop: 'type',label: '售卖类型',formatter: row => this.typeState(row)},
          {prop: 'isbuy',label: '是否可买',formatter: row => this.buyState(row)},
          {prop: 'createTime',label: '创建时间'},
          {prop: 'updateTime',label: '更新时间'},
      ]
      const searchForm = [
          {
              type: 'date-picker',
              id: 'date',
              label: '时间筛选',
              el: {
                  type: 'daterange',
                  valueFormat: 'yyyy-MM-dd'
              },
              inputFormat: (row) => {
                  if (row.startdate && row.enddate) {
                      return [row.startdate, row.enddate]
                  }
              },
              outputFormat: (val) => {
                  if (!val) {
                      return ""
                  }
                  return {
                      startdate: val[0],
                      enddate: val[1]
                  }
              }
          },
          {
            type: 'input',
            id: 'goodsname',
            label: '商品名称',
            el: {placeholder: '请输入商品名称', clearable: true},
          },
          {
            type: 'input',
            id: 'detail',
            label: '商品详情',
            el: {placeholder: '请输入商品详情', clearable: true},
          },
          {
            type: 'input',
            id: 'country',
            label: '售卖国家',
            el: {placeholder: '请输入售卖国家', clearable: true},
          },
      ]
      return {
        columns,
        typeOptions,
        isbuyOptions,
        userinfo:{},
        typeList:[],
        countryList:[],
        tableConfig: {
          searchForm,
          url: 'goodsspu/list',
          dataPath: 'data.list',
          totalPath: 'data.total',
          paginationSize:10,
          extraButtons: [
            {
              type: 'primary',
              text: '编辑',
              icon: 'el-icon-edit-outline',
              show:()=>{return this.hasRole('goodsspu:update')},
              atClick: async row => {
                this.handleEdit(row)
                return false
              },
            },
            {
              type: 'primary',
              text: '删除',
              icon: 'el-icon-delete',
              show:()=>{return this.hasRole('goodsspu:delete')},
              atClick: async row => {
                this.handleDelete(row)
                return false
              },
            },
          ],
          headerButtons: [
            {
              text: '新增',
              type: 'primary',
              show:()=>{return this.hasRole('goodsspu:insert')},
              atClick: async () => {
                this.handleInsert()
                return false
              },
            },
            {
              text: '批量删除',
              type: 'primary',
              show:()=>{return this.hasRole('goodsspu:batchDelete')},
              disabled: selected => selected.length < 1,
              atClick: selected => {
                let ids = selected.map(item => item.id)
                this.Batch('delete', ids)
                return false
              },
            },
          ],
          hasDelete: false,
          hasEdit: false,
          hasOperation: true,
          hasNew: false,
          operationAttrs: {
            width: '150px',
            fixed: 'right',
          },
        },
        form:this.initForm(),
        title:"",
        type:"",
        dialogFormVisible:false,
        formLabelWidth:"100px",
        rules: {
            id :[
              {required: true, message: '请输入序号', trigger: 'blur'},],
            typeid :[
              {required: true, message: '请输入商品类型', trigger: 'blur'},],
            goodsname :[
              {required: true, message: '请输入商品名称', trigger: 'blur'},],
            price :[
              {required: true, message: '请输入商品价格', trigger: 'blur'},],
            detail :[
              {required: true, message: '请输入商品详情', trigger: 'blur'},],
            homeimg:[
                {required: true, message: '请上传首页图片', trigger: 'blur'},],
            img :[
              {required: true, message: '请上传商品图片', trigger: 'blur'},],
            country :[
              {required: true, message: '请输入售卖国家(默认全部,默认每个国家都买)', trigger: 'blur'},],
            num :[
              {required: true, message: '请输入销售数量', trigger: 'blur'},],
            maxnum :[
                {required: true, message: '请输入车辆长度', trigger: 'blur'},],
            type :[
                {required: true, message: '请输入售卖类型', trigger: 'blur'},],
            isbuy :[
                {required: true, message: '请选择是否可购买', trigger: 'blur'},],
            createTime :[
              {required: true, message: '请输入创建时间', trigger: 'blur'},],
            updateTime :[
              {required: true, message: '请输入更新时间', trigger: 'blur'},],
            ext1 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext2 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext3 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
            ext4 :[
              {required: true, message: '请输入扩展字段', trigger: 'blur'},],
        },
          editorInit: {
              language: 'zh_CN',
              convert_urls: false,
              height: 300,
              plugins: ['advlist anchor autolink autosave code codesample colorpicker colorpicker contextmenu directionality emoticons fullscreen hr image imagetools importcss insertdatetime link lists media nonbreaking noneditable pagebreak paste preview print save searchreplace spellchecker tabfocus table template textcolor textpattern visualblocks visualchars wordcount kityformula-editor'],
              toolbar: ['bold italic underline strikethrough alignleft aligncenter alignright outdent indent  blockquote undo redo removeformat subscript superscript code codesample',
                  'hr numlist link image charmap preview anchor pagebreak insertdatetime media table emoticons forecolor backcolor fullscreen',
                  'fontselect formatselect fontsizeselect kityformula-editor'],
              images_upload_handler: function(blobInfo, success, failure) {
                  const formData = new FormData()
                  formData.append('file', blobInfo.blob())
                  uploadFile(formData).then(res => {
                      success(res.data)
                  }).catch(() => {
                      failure('上传失败，请重新上传')
                  })
              }
          }
      }
    },
    computed: {
          check: {
              get () {
                  if (this.form.country.length === this.countryList.length) {
                      return true
                  }
                  return false
              },
              set () {}
          }
    },
    methods:{
        selectAll (checked) {
            if (checked) {
                this.form.country = this.countryList.map(d => d.twocode)
            } else {
                this.form.country  = []
            }
        },
        hasRole(val){
            return this.hasPerm(val)
        },
        typeState(row){
          let data
          this.typeOptions.forEach(r=>{
              if (r.value==row.type){
                  data=r.label
              }
          })
          return data
        },
        buyState(row){
            let data
            this.isbuyOptions.forEach(r=>{
                if (r.value==row.isbuy){
                    data=r.label
                }
            })
            return data
        },
        imgState(row){
            return <img style="height:100px" src={row.img}/>
        },
        homeimgState(row){
            return <img style="height:30px;width:100px;" src={row.homeimg}/>
        },
        handleAvatarSuccess(res, file) {
            if (res.status=="200"){
                this.form.img=res.data
            }else{
                MessageError(res.msg)
            }
            console.log(file)
        },
        handleAvatarSuccessHome(res, file) {
            if (res.status=="200"){
                this.form.homeimg=res.data
            }else{
                MessageError(res.msg)
            }
            console.log(file)
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg'||'image/png';
            const isLt2M = file.size / 1024 / 1024 < 5;

            if (!isJPG) {
                this.$message.error('上传图片只能是 JPG|png 格式');
            }
            if (!isLt2M) {
                this.$message.error('上传图片大小不能超过 5MB!');
            }
            return isJPG && isLt2M;
        },
      initForm() {//初始数据
        return {
          id : "", // 序号
          typeid : "", // 商品类型id
          goodsname : "", // 商品名称
          price : "", // 商品价格
          detail : "", // 商品详情
          homeimg:"",
          img : "", // 商品图片
          country : "", // 售卖国家(默认全部,默认每个国家都买)
          num : "", // 销售数量
          maxnum:"", // 车辆长度
          type:"",  // 售卖类型
          isbuy:"", //是否可购买
          createTime : "", // 创建时间
          updateTime : "", // 更新时间
          ext1 : "", // 扩展字段
          ext2 : "", // 扩展字段
          ext3 : "", // 扩展字段
          ext4 : "", // 扩展字段
        }
      },
      handleEdit(row) {
        console.log(row)
        this.form = row
          this.form.country=this.form.country.split(",")
        this.openDialog();
        this.title = "修改信息"
        this.type = "update"
      },
      handleDelete(row) {
        this.$confirmel('此操作将永久删除该文件, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {
            this.operatorDeleteUser(row)
        }).catch(() => {
            MessageInfo("已取消删除");
        });
      },
      async operatorDeleteUser(row) {
        let res = await deleteGoodsSpu(row.id)
        if (res.status == "200") {
          MessageSuccess(res.msg)
        } else {
          MessageError(res.msg)
        }
        this.$refs.table.getList()
      },
      handleInsert() {
        this.openDialog();
        this.title = "新增"
        this.type = "insert"
      },
      async Batch(type, ids) {
        if (type == "delete") {
          let res = await bacthDelGoodsSpu(ids)
          if (res.status == "200") {
            MessageSuccess(res.msg)
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },
      openDialog() {
        this.dialogFormVisible = true
      },
      closeDialog() {
        this.dialogFormVisible = false
        this.form = this.initForm();
        this.$refs.ruleUser.clearValidate();
        this.$refs.table.getList()
      },
      async submitUser() {
        this.$refs.ruleUser.validate((valid) => {
          if (valid) {
            this.operatorUser()
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      async operatorUser() {
        let res;
        this.form.country=this.form.country.toString()
        if (this.type == "insert") {
          res = await insertGoodsSpu(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        } else if (this.type == "update") {
          res = await updateGoodsSpu(this.form)
          if (res.status == "200") {
            MessageSuccess(res.msg)
            this.closeDialog()
          } else {
            MessageError(res.msg)
          }
        }
        this.$refs.table.getList()
      },
      getUserInfo(){
        let deadline = sessionStorage.getItem('deadline');
        let userInfo = sessionStorage.getItem("userInfo");
        let token = sessionStorage.getItem('token');
        if(!deadline||!userInfo||!token) {
          this.removeInfo()
          MessageError("请先登录！")
          this.$router.push({ path: "/login" });
          return false
        }else{
          let now = new Date().getTime();
          if (deadline<now){
            this.removeInfo()
            MessageError("请先登录！")
            this.$router.push({ path: "/login" });
            return false
          }
        }
        return userInfo;
      },
      removeInfo(){
        sessionStorage.removeItem("userInfo");
        sessionStorage.removeItem("deadline");
        sessionStorage.removeItem("token");
      },
      async getGoodstypeListInfo() {
          let res = await getGoodstypeList()
          if (res.status == "200") {
              this.typeList=res.data.list
          } else {
              MessageError(res.msg)
          }
      },
      async getCountryinfoAllInfo() {
          let res = await getCountryinfoAll()
          if (res.status == "200") {
              this.countryList = res.data
          } else {
              MessageError(res.msg)
          }
      },
    },
    created() {
      let userInfo=this.getUserInfo()
      if (userInfo){
        this.userinfo=JSON.parse(userInfo)
        this.tableConfig.axiosConfig={
          headers: {
            'Authorization': 'X-KILOGOD-AUTHORIZATION '+sessionStorage.getItem('token'),

          }
        }
        if (!this.hasRole('goodsspu:update')&&!this.hasRole('goodsspu:delete')){
              this.tableConfig.hasOperation=false
        }
        this.getGoodstypeListInfo()
        this.getCountryinfoAllInfo()
      }
    }
  }
</script>


<style>
    .avatar-uploader .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 240px;
        height: 240px;
        line-height: 240px;
        text-align: center;
    }
    .avatar {
        width: 240px;
        height: 240px;
        display: block;
    }

    .avatar-uploader-icon1 {
        font-size: 28px;
        color: #8c939d;
        width: 240px;
        height: 160px;
        line-height: 160px;
        text-align: center;
    }
    .avatar1 {
        width: 240px;
        height: 160px;
        display: block;
    }
</style>
